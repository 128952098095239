<template>
  <page title="咨询提交">
    <div class="consulting_service_two_banner">
      <div class="consulting_service_two_banner_title">
        <h1>留言咨询</h1>
        <p>为群众提供多种咨询方式的留言咨询。</p>
      </div>
    </div>
    <div class="mt-4 mb-4 overflow-hidden" v-if="showmes === 1">
    <div class="container">
      <div class="consulting_service_two_container_left">
        <div class="consulting_service_two_container_left_list">
          <div class="consulting_service_two_container_left_list_title">
            <h3>法律服务咨询方式</h3>
          </div>

          <div class="tab">
            <Collection  name="service_type"
      v-slot="{ collection }"  :filter="{ parent: service_typep }" :limit="1000"
      :query="{ parent: service_typep }">
            <ul>
              <li class="fyzx" v-for="value in collection" :key="value.item_value"   @click="setServertype(value.item_value,value.item_name)">
                <a :class="{ on: service_type === value.item_value }"
                  >{{value.item_name}}</a>
              </li>
            </ul>
            </Collection>
          </div>
        </div>
      </div>

      <div class="consulting_service_two_container_right">
        <div class="consulting_service_two_container_right_middle">
          <h3>咨询需知</h3>
          <div class="consulting_service_two_container_right_middle_content">
            <div
              class="consulting_service_two_container_right_middle_content_textarea"
            >
              <ul class="border p-3 mb-3">
                <li>1、本栏目是本站为群众提供法律问题咨询服务板块，解答内容仅供您参考之用，不具备法律效力。</li>
                <li>2、本栏目内容全部公开，请不要填写您个人或他人的隐私信息。</li>
                <li>3、请您把要咨询的问题尽量描述清楚，以便进行有针对性的回答。</li>
                <li>4、不要包含侮辱、诽谤及违法用语。</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="consulting_service_two_container_right_top">
          <h3>{{servertitle}}咨询类型</h3>
          <div class="consulting_service_two_container_right_top_list content">
            <ul>
              <li>
                 <Collection  name="business_type" :limit="1000" v-slot="{ collection }" :filter="{ parent: service_type }">
                <div class="consulting_service_two_container_right_top_list_li">
                  <a v-for=" value in collection" :key="value.item_value" :class="{active : business_type == value.item_value}" @click="business_type = value.item_value">{{value.item_name}}</a>
                </div>
                 </Collection>
              </li>
            </ul>
          </div>
        </div>
    <a-form-model   ref="ruleForm">
       <!-- <div class="consulting_service_two_container_right_middle-1">
          <h3>请描述您要咨询的标题</h3>
          <div class="zxbtDiv">
            <div
              class="consulting_service_two_container_right_middle_content_textarea-1"
            >
             <a-form-model-item label="" prop="order_title">
            <a-input type="text" placeholder="请输入咨询事项" v-model="formbase.order_title"/>
             </a-form-model-item>
            </div>
          </div>
        </div>-->
        <div class="consulting_service_two_container_right_middle">
          <h3>请描述您要咨询的内容</h3>
          <div class="consulting_service_two_container_right_middle_content">
            <div
              class="consulting_service_two_container_right_middle_content_textarea"
            >
              <textarea
                maxlength="1000"
                class="zxnr"
                placeholder="请详细描述您要咨询的问题及背景情况，但不要透露具体人名、公司名称、地址等可能、涉及隐私的内容；1000字以内。"
                v-model="formbase.order_desc"
              ></textarea>
              <div
                class="consulting_service_two_container_right_middle_content_submit"
              >
                <h3>最多输入1000字</h3>
                <input
                  style="cursor: pointer"
                  type="submit"
                  class="submitBtn"
                  @click="handleSubmit"
                  value="立即提交"
                 >
              </div>
            </div>
            <div class="mt-3 consulting_service_two_container_right_middle_content_textarea">
            <a-alert
              message="风险提醒: "
              description="回复内容系法律服务人员基于咨询者提供的信息结合对法律的理解给出的咨询意见，仅供参考，不具备法律效力。"
              type="error"
              show-icon
            />
            </div>
          </div>
        </div>
    </a-form-model>
   <Collection  name="workorder" :query="{ order_type: '咨询' }"
      :limit="provider.defaultPageSize"
      :page="provider.current"
      v-slot="{ collection, count }"
      >
        <div class="consulting_service_two_container_right_bottom">
          <h3 style="display: inline; margin-left:35px">全部咨询</h3>
          <div class="consulting_service_two_container_right_bottom_content">
            <ul class="yjdal">
              <li class="detail"  v-for="value in collection" :key="value.workorder_id" value="4931" style="cursor: pointer">
                <div
                  class="consulting_service_two_container_right_bottom_content_list_left"
                >
                  <p><router-link :to="`/adviceinfo/${value.workorder_id}`">{{value.order_desc}}</router-link></p>
                </div>
                <p>{{value.status}}</p>
                <p>{{
                      moment(value.create_time).format('MM-DD')
                    }}</p>
              </li>
            </ul>
          </div>
          <div class="yjdal_page">
            <a-pagination
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
          </div>
        </div>
 </Collection>
      </div>
    </div>
    </div>
    <div class="msgbox" v-if="showmes === 2">
      <a-result
    status="success"
    :title="msg.title"
    :sub-title="msg.desc"
  >
    <template #extra>
      <a-button  @click="userinfo" type="primary">
        个人中心
      </a-button>
      <a-button  @click="sub">
        返回首页
      </a-button>
    </template>
  </a-result>
    </div>
  </page>
</template>
<script>
import Collection from '@/components/Collection'
import model from '../../core/model.js'
export default {
  name: 'adviceAdd',
  mixins: [model],
  components: {
    Collection
  },
  activated () {
    // 登录验证
    this.goLoginTip(this.$route.path)
  },
  data () {
    var type = this.$route.query.type
    var servertitle = '法律援助'
    if (!type) {
      type = '咨询-法律援助'
      servertitle = '法律援助'
    } else {
      servertitle = type
    }
    var businesstype = this.$route.query.business_type
    if (!businesstype) {
      businesstype = ''
    }
    var msg = {
      title: '提交成功',
      desc: '您的咨询信息已经提交，请耐心等待'
    }
    return {
      service_typep: '咨询',
      service_type: type,
      showmes: 1,
      msg: msg,
      servertitle: servertitle,
      formbase: {
        order_desc: '',
        platform_channel: '门户',
        platform_type: '网络',
        business_type: businesstype,
        province: 'XX省',
        order_type: '咨询'
      },
      business_type: businesstype,
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if (this.$route.query.business_type) {
        this.business_type = this.$route.query.business_type
      }
      if (this.$route.query.type) {
        this.service_type = this.$route.query.type
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    },
    userinfo () {
      window.location.href = '/my'
    },
    sub () {
      this.$router.push('/')
    },
    setServertype (value, title) {
      this.service_type = value
      this.servertitle = title
      this.business_type = ''
    },
    handleSubmit (e) {
      var that = this
      var formdata = this.formbase
      if (formdata.order_desc === '') {
        this.$message.error('事项描述不能为空')
        return false
      }
      if (this.business_type === '农民工' || this.business_type === '退伍军人') {
        formdata.order_level = 3
        formdata.person_type = this.business_type
      }
      formdata.service_type = this.service_type
      formdata.business_type = this.business_type
      formdata.sponsor_phone = this.$Cookies.get('phone')
      formdata.sponsor = this.$Cookies.get('uid')
      var result = that.saveData({ url: 'my-workorder/apply', data: formdata })
      if (result) {
        that.showmes = 2
      }
    }
  }
}
</script>
<style scoped src="../../assets/css/advice.css"></style>
